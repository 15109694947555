 const removeMask = (value) => {
  return value.replace(/\D/g, '');
}
const cleanObject = (obj) => {
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      cleanObject(obj[key]);
    } else if (typeof obj[key] === 'string' && obj[key].includes('-')) {
      obj[key] = removeMask(obj[key]);
    }
  }
}

export const removeMaskedFields = (data) => {
  const cleanedData = JSON.parse(JSON.stringify(data));
  cleanObject(cleanedData);
  return cleanedData;
};

export const removeMasks = (value) => {
  return value.replace(/\D/g, '');
}