import React, {useState, useEffect} from 'react';
import styled from '@emotion/styled';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import {Link} from 'react-router-dom';

import logoMenu from '../../assets/images/logoMenu.png';
import Flex from '../../components/flex';
import { DASHBOARD, MOVIMENTACAO_FINANCEIRA, FECHAMENTO, VENDAS, RELATORIOS, PLANOS_E_ADICIONAIS, CADASTROPACIENTE } from '../../router/names';
import ItemMenu from './item-menu';
import {obterEntidadeAtualLocal} from "../../services/usuario-service";
import { BoxIcon, PeopleIcon, MoneyIcon, PrintIcon } from '../../components/icons';
import { checkUserRole } from '../../utils/checkUserRole';
import { useLazyQuery, useQuery } from 'react-apollo';
import { FIND_WHITE_LABEL, LINK_LOGIN_AZUL_CONTROLE_COMERCIAL } from '../../graphql/queryes';
import { PersonAddIcon } from '../../components/icons/PersonAdd';

const Menu = ({ hasMenu }) => {

  const [getLinkAzulControle, {
    data: linkAzulControle,
  }] = useLazyQuery(LINK_LOGIN_AZUL_CONTROLE_COMERCIAL, {
    onCompleted: () => {
      const link = linkAzulControle?.linkLoginComercialAzulControle;
      window.open(link, '_blank');
    },
  });

  const { data } = useQuery(FIND_WHITE_LABEL)
  const whitelabel = data?.findEntidadeLogada?.whitelabel?.nome 

  const [entidadeLogada, setEntidadeLogada] = useState(null);
  const [isOperacional, setIsOperacional] = useState(false);



  const getEntidadeLogada = async () => {
    return await obterEntidadeAtualLocal();
  }

  const checkIsOperacional = async () => {
    const isOperacional = await checkUserRole('ROLE_OPERACIONAL');
    setIsOperacional(isOperacional);
  }

  const urlSistema = process.env.REACT_APP_APPHEALTH_URL + "/#/login?documento=";

  useEffect(() => {
    getEntidadeLogada().then(response => {
      setEntidadeLogada(response);
    })
    checkIsOperacional()
  },[]);

  if (isOperacional) {
    return (
      <Container flexDirection="column">
        <ItemMenu title="Vendas" to={VENDAS} icon={MoneyIcon}/>
      </Container>
    )
  }

  const isTaip = whitelabel === 'TAIP'
	return (
		<Container flexDirection="column">
      {entidadeLogada?.nivel?.nome === "Comercial" ?
        <a title={"Sistema"} href={urlSistema + entidadeLogada?.responsavelCpf} target={"_blank"}>
				  <LogoContainer>
				  	<Logo src={logoMenu} alt="logo" />
				  </LogoContainer>
        </a>
        :
        <Link to={!!hasMenu ? DASHBOARD : '#'}>
          <LogoContainer>
            <Logo src={logoMenu} alt="logo" />
          </LogoContainer>
        </Link>
      }
			{
				!!hasMenu && <>
					<ItemMenu title="Entidades" to={DASHBOARD} icon={PeopleIcon} />
					<ItemMenu title="Movimentação Financeira" to={MOVIMENTACAO_FINANCEIRA} icon={ReceiptOutlinedIcon} />
					<ItemMenu title="Fechamento" to={FECHAMENTO} icon={AccountBalanceWalletOutlinedIcon} />
					<ItemMenu
            title="Planos e adicionais"
            to={PLANOS_E_ADICIONAIS}
            icon={BoxIcon}
            hidden={!checkUserRole('ROLE_SISTEMA')}
          />
					<ItemMenu title="Vendas" to={VENDAS} icon={MoneyIcon} />
					<ItemMenu
            title="Financeiro"
            hidden={entidadeLogada?.nivel?.nome !== "Sistema"}
            onClick={getLinkAzulControle}
            icon={MonetizationOnIcon}
          />
					<ItemMenu title="Relatórios" to={RELATORIOS} icon={PrintIcon} />	
          { isTaip && <ItemMenu title="Cadastro de pacientes" to={CADASTROPACIENTE} icon={PersonAddIcon} />}
				</>
			}
		</Container>
	)
};

const Container = styled(Flex)`
	flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
`;

const LogoContainer = styled.div`
	display: flex;
    align-items: center;
    justify-content: center;
    margin: 17px 0;
`;
const Logo = styled.img`
	width: 75.7143%;
`;

export default Menu;
