import { Box, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ButtonFloat from "../../components/button/ButtonFloat";
import Notification from "../../components/notification/Notification";
import { withBaseLayout } from "../../layout/base-layout";
import { useLazyQuery, useMutation, useQuery } from "react-apollo";
import List from "../../components/list/list";
import ModalSlider from "../../components/modal-slider/modal-slider";
import { TextField } from "../../components/TextField";
import styled from "@emotion/styled";
import Button from "../../components/button";
import { removeMasks } from "../../utils/removeMask"
import { CREATE_PACIENTE_COMERCIAL } from "../../graphql/mutations";
import { FIND_ALL_PACIENTE_COMERCIAL } from "../../graphql/queryes";
import Input from "../../components/input/Input";


const notificationDefault = {
    variant: '',
    message: '',
    isOpen: false,
};

const pageableDefault = {
    pageNumber: 0,
    pageSize: 30,
    sortDir: "ASC",
    sortField: "nome"
}

const CadastrarPaciente = () => {

    const [pageable, setPageable] = useState({ ...pageableDefault })
    const [openModalNovo, setOpenModalNovo] = useState(false)
    const [loadingSave, setLoadingSave] = useState(false)
    const [dadosCadastrais, setDadosCadastrais] = useState(false)
    const [notification, setNotification] = useState({
        ...notificationDefault,
    });
    const [searchDTO, setSearchDTO] = useState({
        search: null,
    });

    const { data, loading, error, refetch, fetchMore } = useQuery(FIND_ALL_PACIENTE_COMERCIAL, {
        variables: {
            search: searchDTO.search,
            pageableDto: pageable
        },
    });

    const {findAllPacientes}=data || {}

    const [criarPacienteComercial] = useMutation(CREATE_PACIENTE_COMERCIAL)

    const handleClickNovo = () => {
        setOpenModalNovo(true);
    };

    const handleCloseModalNovo = () => {
        setOpenModalNovo(false);
        setDadosCadastrais({})
    }

    const senhaConfere = () => {
        const { senha, confirmacaoSenha } = dadosCadastrais
        return (senha === confirmacaoSenha)
    }

    const handleClickSave = async () => {
        try {
            setLoadingSave(true)
            if (senhaConfere()) {

                const variables = {
                    paciente: {
                        nome: `${dadosCadastrais.nome} ${dadosCadastrais.sobrenome}`,
                        email: dadosCadastrais.email,
                        cpf: removeMasks(dadosCadastrais.cpf),
                        telefonePrincipal: removeMasks(dadosCadastrais.telefone),
                    },
                    senha: dadosCadastrais.senha
                }

                const response = await criarPacienteComercial({ variables: variables })

            } else {
                setNotification({ isOpen: true, variant: "error", message: "As senhas não conferem!" })
            }

        } catch (e) {
            console.error(e)

        } finally {
            setLoadingSave(false)
        }
    }

    const handleLoadMore = async (e) => {

        e.preventDefault();
        if (!data?.findAllPacientes?.last) {

            await fetchMore({
                variables: {
                    pageableDto: { ...pageable, pageNumber: data.findAllPacientes.number + 1 },
                    ...searchDTO
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;

                    const findAll = {
                        findAllPacientes: {
                            ...prev.findAllPacientes,
                            ...fetchMoreResult.findAllPacientes,
                            content: [
                                ...prev.findAllPacientes.content,
                                ...fetchMoreResult.findAllPacientes.content
                            ]
                        }
                    }
                    return findAll

                }
            });
        }
    }

    const handleDadosCadastrais = (value, field) => {
        setDadosCadastrais((prevState) => {
            return {
                ...prevState,
                [field]: value,
            }
        })
    }

    const handleClickPaciente = (e) => {
        try {
            const { telefonePrincipal, ...rest } = e
            setDadosCadastrais({ ...rest, telefone: telefonePrincipal })
            setOpenModalNovo(true)

        } catch (e) {
            console.error(e)
        }
    }

    const handleChange = (e) => {
        setSearchDTO({ ...searchDTO, search: e.target.value })
        refetch()
    }

    const { senha, confirmacaoSenha, nome, cpf, email, telefone, sobrenome } = dadosCadastrais
    const disabled = !senha || !confirmacaoSenha || !nome || !cpf || !email || !telefone || !sobrenome

    return (
        <Box>
            <RowFilter>
                <Input
                    onChange={handleChange}
                />
            </RowFilter>

            <List
                columns={[
                    {
                        title: 'Nome',
                        value: (item) => item.nome
                    },
                    {
                        title: 'CPF',
                        value: (item) => item.nome,
                    },
                    {
                        title: 'Telefone',
                        value: (item) => item.nivel ? item.nivel.nome : '-',
                    },
                ]}
                items={findAllPacientes?.content || []}
                loading={loading}
                error={error}
                last={data?.findAllPacientes?.last}
                onLoadMore={handleLoadMore}
                onClickItem={handleClickPaciente}
            />
            {<ButtonFloat title="Novo" onClick={handleClickNovo} />}
            {openModalNovo &&
                <ModalSlider
                    isOpen={openModalNovo}
                    onClose={handleCloseModalNovo}
                    title={`${dadosCadastrais.id ?'Informações do': "Novo"} paciente`}
                >
                    <Wrapper>
                        <Row>
                            <TextField
                                label="Nome"
                                placeholder="Digite o nome"
                                value={dadosCadastrais?.nome}
                                onChange={(e) => handleDadosCadastrais(e.target.value, 'nome')}
                                disabled={dadosCadastrais?.id}
                            />
                        </Row>
                        {!dadosCadastrais?.id && <Row>
                            <TextField
                                label="Sobrenome"
                                placeholder="Digite o sobrenome"
                                value={dadosCadastrais.sobrenome}
                                onChange={(e) => handleDadosCadastrais(e.target.value, 'sobrenome')}
                            />
                        </Row>}
                        <Row>
                            <TextField
                                label="CPF"
                                mask="cpf"
                                placeholder="Digite o cpf"
                                value={dadosCadastrais.cpf}
                                onChange={(e) => handleDadosCadastrais(e.target.value, 'cpf')}
                                disabled={dadosCadastrais?.id}
                            />
                        </Row>
                        <Row>
                            <TextField
                                label="Telefone"
                                mask="phone"
                                placeholder="Digite o telefone"
                                value={dadosCadastrais.telefone}
                                onChange={(e) => handleDadosCadastrais(e.target.value, 'telefone')}
                                disabled={dadosCadastrais?.id}
                            />
                        </Row>
                        <Row>
                            <TextField
                                label="Email"
                                placeholder="Digite o email"
                                value={dadosCadastrais.email}
                                onChange={(e) => handleDadosCadastrais(e.target.value, 'email')}
                                disabled={dadosCadastrais?.id}
                            />
                        </Row>
                        {!dadosCadastrais?.id &&<Row>
                            <TextField
                                label="Senha"
                                placeholder="Digite a senha"
                                value={dadosCadastrais.senha}
                                onChange={(e) => handleDadosCadastrais(e.target.value, 'senha')}
                            />
                             <TextField
                                label="Confirme a senha"
                                placeholder="Confirme a senha"
                                value={dadosCadastrais.confirmacaoSenha}
                                onChange={(e) => handleDadosCadastrais(e.target.value, 'confirmacaoSenha')}
                            />
                        </Row>}
                        {!dadosCadastrais?.id && <Row>
                            <Button disabled={disabled || loadingSave} onClick={handleClickSave}>
                                Salvar
                                {loadingSave && <CircularProgress color="#fff" size={12} />}
                            </Button>
                        </Row>}
                    </Wrapper>

                </ModalSlider>}
            <Notification
                close={() => {
                    setNotification({
                        ...notificationDefault,
                    });
                }}
                reset={() => {
                    setNotification({
                        ...notificationDefault,
                    });
                }}
                isOpen={notification.isOpen}
                variant={notification.variant}
                message={notification.message}
            />
        </Box>
    )
}

const Row = styled.div`
  display: flex;
  gap: 5px;
`;

const Wrapper = styled.div`
    display:flex;
    flex-direction: column;
    gap: 10px;
    margin: 5px;
`

const RowFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 300px;
  margin: 5px;
`;


export default withBaseLayout(CadastrarPaciente)