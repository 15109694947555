import React from 'react';

import {
	Route,
} from 'react-router-dom';
import ListEntity from '../features/list-entity';
import {
  EDIT_ENTITY,
  MOVIMENTACAO_FINANCEIRA,
  FECHAMENTO,
  VENDAS,
  CRIAR_VENDA,
  VISUALIZAR_VENDA,
  CREATE_ENTITY,
  RELATORIOS,
  PLANOS_E_ADICIONAIS,
  PERFIL_PUBLICO_PROFISSIONAL_VENDA,
  PERFIL_PUBLICO_UNIDADE_VENDA,
  CONFIGURACAO_RECEBIMENTOS_UNIDADE,
  CADASTROPACIENTE,
} from './names';
import EditEntity from '../features/edit-entity';
import ApolloInstance from '../apollo/setup';
import ListMovimentacaoFinanceira from '../features/list-movimentacao-financeira';
import ListFechamento from '../features/list-fechamento';
import CriarVenda from '../features/criar-venda';
import ListVendas from '../features/list-vendas';
import VisualizarVenda from '../features/visualizar-venda';
import Relatorios from '../features/relatorios';
import PlanosEAdicionais from '../features/planos-e-adicionais';
import {
  PerfilPublicoProfissionalVenda,
  PerfilPublicoUnidadeVenda,
} from '../features/perfil-publico';
import ConfigRecebimentosUnidade from '../features/config-recebimentos-unidade';
import { RecebimentosProvider } from '../features/config-recebimentos-unidade/recebimentos-context';
import CadastrarPaciente from '../features/cadastro-pacientes';

const DashboardRouter = () => {
  return (
    <ApolloInstance>
      <Route exact path="/dashboard">
        <ListEntity />
      </Route>
      <Route path={EDIT_ENTITY}>
        <EditEntity />
      </Route>
      <Route exact path={MOVIMENTACAO_FINANCEIRA}>
        <ListMovimentacaoFinanceira />
      </Route>
      <Route exact path={FECHAMENTO}>
        <ListFechamento />
      </Route>
      <Route exact path={PLANOS_E_ADICIONAIS}>
        <PlanosEAdicionais />
      </Route>
      <Route exact path={VENDAS}>
        <ListVendas />
      </Route>
      <Route path={CRIAR_VENDA}>
        <CriarVenda />
      </Route>
      <Route path={VISUALIZAR_VENDA}>
        <VisualizarVenda />
      </Route>
      <Route path={PERFIL_PUBLICO_PROFISSIONAL_VENDA}>
        <PerfilPublicoProfissionalVenda />
      </Route>
      <Route path={PERFIL_PUBLICO_UNIDADE_VENDA}>
        <PerfilPublicoUnidadeVenda />
      </Route>
      <Route path={CONFIGURACAO_RECEBIMENTOS_UNIDADE}>
        <ConfigRecebimentosUnidade />
      </Route>
      <Route path={CREATE_ENTITY}>
        <EditEntity />
      </Route>
      <Route path={RELATORIOS}>
        <Relatorios />
      </Route>
      <Route path={CADASTROPACIENTE}>
        <CadastrarPaciente />
      </Route>
    </ApolloInstance>
  );
};

export default DashboardRouter;
